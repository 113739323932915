@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@200;300;400;500;600;700&display=swap");
:root {
  --gray: rgba(24, 129, 225, 0.4);
}

*, *::before, *::after {
  box-sizing: border-box;
}

.l {
  box-shadow: inset 0 0 0 1px red;
}

body {
  font-family: "M PLUS 1", sans-serif;
  background: #FAFAFA !important;
}

/* Firefox */
* {
  scrollbar-width: auto;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(219, 213, 213, 0.7882352941);
  border-radius: 10px;
  border: 2px solid #FFFFFF;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #767FFE;
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

.icon-1p25 {
  transform: scale(1.25);
}

.icon-1p30 {
  transform: scale(1.3);
}

.icon-1p35 {
  transform: scale(1.35);
}

.icon-1p50 {
  transform: scale(1.5);
}

.icon-1p75 {
  transform: scale(1.75);
}

.icon-2x {
  transform: scale(2);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0 !important;
  margin: 0 !important;
}

.l {
  box-shadow: inset 0 0 0 1px red;
}

.text-gray {
  color: #A3A3A3;
}

.text-green {
  color: #72EE38;
}

.text-blue {
  color: #183972;
}

.text-orange {
  color: #FFB84E;
}

.text-lightblue {
  color: #407BFF;
}

.text-pink {
  color: #F86D6D;
}

.text-cyan {
  color: #39C9C1;
}

.text-purple {
  color: #B910BC;
}

.text-yellow {
  color: #E89416;
}

.bg-gray {
  background: #F9F9F9 !important;
}

.bg-green {
  background: #72EE38;
}

.bg-lightgreen {
  background: #E6FFDA;
}

.bg-pink {
  background: #F86D6D;
}

.bg-lightpink {
  background: #FFF8F8;
}

.bg-blue {
  background: #102ABC;
}

.bg-lightblue {
  background: #EAEEFF;
}

.bg-cyan {
  background: #39C9C1;
}

.bg-lightcyan {
  background: #ECFFFE;
}

.bg-purple {
  background: #B910BC;
}

.bg-lightpurple {
  background: #FEDDFF;
}

.bg-yellow {
  background: #E89416;
}

.bg-lightyellow {
  background: #FFF0D9;
}

.fs-9 {
  font-size: 9px;
}

.fs-14 {
  font-size: 14px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.mxw-250 {
  max-width: 250px;
}

.w30px {
  width: 30px;
}

.h30px {
  height: 30px;
}

.w10 {
  width: 10%;
}

.w90 {
  width: 90%;
}

.w-200px {
  width: 200px;
}

.wh-20px {
  width: 20px !important;
  height: 20px !important;
}

.wh-40px {
  width: 40px !important;
  height: 40px !important;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  position: fixed !important;
  top: 0;
  right: 0;
  background: #EBEEFF;
  z-index: 20;
}
.navbar .userdrop {
  border: none;
  background: none;
}
.navbar .userdrop img {
  border-radius: 50%;
  margin-right: 5px;
}
.navbar .btn-group .dropdown-menu {
  border: none;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1490196078);
}
.navbar .btn-group .dropdown-menu li {
  padding: 5px 0;
}
.navbar .btn-group .dropdown-menu .img-box {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.navbar .btn-group .dropdown-menu .img-box img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.navbar .btn-group .dropdown-menu .text-box {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}
.navbar .btn-group .dropdown-menu .drop-btn {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: start;
  display: flex;
  align-items: center;
}
.navbar .btn-group .dropdown-menu .drop-btn:hover {
  color: #16057E;
}
.navbar .btn-group .dropdown-menu .drop-btn .arrow {
  right: 10px;
}

.nav-btn {
  left: 0;
  top: 60px;
  position: sticky;
  margin-top: -40px;
}

.logout-btn-line {
  background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  padding: 4px 12px;
  color: #FFFFFF;
  transition: 200ms;
}
.logout-btn-line:hover {
  background: #0E2074;
  border: 1px solid #0E2074;
  color: #FFFFFF;
}

.wrapper-box {
  padding-top: 15px;
}

aside {
  width: 100px;
  height: calc(100vh - 62px);
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
  padding: 15px;
}
aside div {
  border-radius: 15px 0 0 15px;
  padding: 10px 0 0 10px;
  text-align: center;
  height: 100%;
  background: rgb(25, 53, 183);
  background: linear-gradient(354deg, rgb(25, 53, 183) 6%, rgb(12, 21, 74) 96%);
}

.wrapper {
  min-height: 100vh;
  margin-left: 115px;
  padding-top: 66px;
  padding-right: 15px;
  position: relative;
}

.wrapper-content {
  min-height: 100vh;
  padding-left: 295px;
  padding-top: 66px;
  padding-right: 15px;
  position: relative;
  z-index: 18;
  background: white;
}

.bg-gray {
  background: #e9e5e6;
}

.grd-btn {
  flex: 1 1 auto;
  padding: 30px;
  display: flex;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
}

.grd-btn:hover {
  background-position: right center !important;
}

.grd-btn {
  background-image: linear-gradient(to right, #183972 0%, #246b9a 51%, #183972 100%);
  padding: 10px 20px;
  border: none;
}

.grd-btn:disabled {
  background: #646fa8;
}

::-moz-placeholder {
  color: #E0E0E0 !important;
  /* Firefox */
}

::placeholder {
  color: #E0E0E0 !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #E0E0E0;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #E0E0E0;
}

hr {
  margin: 1rem 0;
  color: #d8d7d7 !important;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

.signin-box {
  width: 100%;
  height: 100vh;
}
.signin-box .loginfram {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10;
}

.signin-left .group {
  width: 100%;
  max-width: 480px;
}

.aside-form ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.aside-form ul li {
  color: #FAFAFA !important;
  padding-bottom: 20px;
}
.aside-form ul li button {
  border: none;
  padding: 5px;
  border-radius: 12px 0 0 12px;
  background: transparent;
  color: #FAFAFA;
  font-weight: 500;
  font-size: 10px;
  width: 100%;
}
.aside-form ul li:hover,
.aside-form ul li :active {
  transition: 5000ms;
}
.aside-form ul li:hover button,
.aside-form ul li :active button {
  background: #FAFAFA !important;
  color: #1934B5 !important;
}
.aside-form ul li:hover span,
.aside-form ul li :active span {
  color: FAFAFA;
}

.login-input {
  width: 100%;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2509803922);
  border: none;
  border-radius: 8px;
  margin: 8px 0;
  outline: none;
  padding: 16px;
  box-sizing: border-box;
  transition: 0.3s;
  border-left: 10px solid #454E8F;
}

.login-input:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

.inputWithIcon .login-input {
  padding-left: 60px;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon i {
  position: absolute;
  left: 0;
  top: 24px;
  padding-left: 20px;
  padding-right: 10px;
  color: #aaa;
  transition: 0.3s;
  border-right: 1px solid #2D6192;
}

.w-auto {
  width: auto !important;
}

.inputWithIcon .login-input:focus + i {
  color: dodgerBlue;
  border-color: dodgerBlue;
}

.inputWithIcon.inputIconBg .login-input:focus + i {
  color: #fff;
  background-color: dodgerBlue;
}

.tabs-row .nav-pills {
  display: flex;
  justify-content: space-between;
}
.tabs-row .nav-pills .nav-item {
  background: #FFFFFF;
  width: 48%;
}
.tabs-row .nav-pills .nav-item .nav-link {
  font-weight: 700;
  font-size: 20px;
}

.nav-item .nav-link {
  background: none;
  border: 1px solid #000000 !important;
  border-radius: 10px !important;
  color: #2A2A2A !important;
  padding: 8px 20px !important;
  width: 100%;
}
.nav-item .nav-link:hover {
  background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%) !important;
  border: 1px solid #112ED8 !important;
  color: #FFFFFF !important;
  transition: 400ms;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF !important;
  background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%);
  border: 1px solid #112ED8 !important;
  border-radius: 29px;
}

.card {
  border-radius: 25px !important;
}
.card .card-box {
  border: none !important;
}
.card .card-box ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.card .card-box ul li {
  font-weight: 400;
  font-size: 16px;
  padding: 8px 20px;
  border-top: 1px solid #E0E0E0;
}
.card .card-box .icon-box {
  width: -moz-min-content;
  width: min-content;
  margin: 15px 0 15px 20px;
}
.card .card-box .btn-box {
  margin: 20px;
}

.tracking-btn {
  font-weight: 600;
  border: 1px solid #0E2181;
  color: #0e2181;
  background: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  width: 120px;
}
.tracking-btn .icon {
  font-size: 28px !important;
  position: absolute;
  right: 40px;
}
.tracking-btn:hover {
  background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%) !important;
  border: 1px solid #112ED8 !important;
  color: #FFFFFF !important;
  transition: 200ms;
}

.dash-card {
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1019607843);
  background: #FFFFFF;
  border-radius: 20px;
  padding: 15px;
  height: 100%;
}

.line {
  border-left: 1px solid #1934B6;
  padding-left: 10px;
}
.line .icon-box {
  padding: 5px;
  background: #102ABC;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pie-chart .apexcharts-legend-series {
  display: flex;
  align-items: center;
  padding: 4px 0;
}
.pie-chart .apexcharts-legend-series span {
  font-weight: 500 !important;
  font-size: 14px !important;
}
.pie-chart .apexcharts-legend-series .apexcharts-legend-marker {
  border-radius: 0px !important;
  width: 20px !important;
  height: 20px !important;
}

.apexcharts-toolbar {
  display: none !important;
}

.normal-tbl table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.normal-tbl table td,
.normal-tbl table th {
  text-align: center;
  padding: 8px;
}
.normal-tbl table .tr-bg {
  background: #F6F6F6 !important;
}
.normal-tbl tr:nth-child(odd) {
  background: #FBFBFB;
  border: 1px solid #f3f3f3;
}
.normal-tbl button {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}
.normal-tbl .edit-btn:hover {
  color: #097BA9;
}
.normal-tbl .delete-btn:hover {
  color: red;
}

.aside-new {
  width: 280px;
  height: calc(100vh - 66px);
  background: #FAFBFC;
  border-right: 1px solid #E9EBF0;
  position: fixed;
  top: 66px;
  left: 0;
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
}
.aside-new.scroll-disabled {
  pointer-events: none;
}
.aside-new .dashboard-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 37px 40px 37px;
}
.aside-new .dashboard-logo a {
  text-decoration: none;
}
.aside-new .dashboard-logo a img {
  min-width: 80px;
}
.aside-new .dashboard-logo button {
  background: transparent;
  cursor: pointer;
  color: #8B8B8B;
  padding: 0;
  border: none;
  outline: none;
  line-height: 1;
  font-size: 30px;
}
.aside-new .dashboard-logo button:hover {
  color: #1A71A7;
}
.aside-new strong {
  padding-left: 20px;
  font-weight: 600;
  font-size: 18px;
}
.aside-new ul {
  list-style-type: none;
  padding: 0 20px;
  margin: 0;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.aside-new ul li {
  padding-bottom: 8px;
}
.aside-new ul li:not(:last-child) {
  border-bottom: 1px solid #F1F1F1;
}
.aside-new ul .nav-link {
  text-decoration: none;
  height: 44px;
  padding-left: 15px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  transition: 300ms;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.aside-new ul .nav-link.active, .aside-new ul .nav-link:hover {
  background: #E9F2FF;
  color: #097BA9;
}
.aside-new ul .nav-link.nav-toggle::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  right: 15px;
  top: calc(50% - 6px);
  transform: rotate(-135deg);
  transition: 300ms;
}
.aside-new ul .nav-link.nav-toggle:hover::before {
  border-left-color: #097BA9;
  border-bottom-color: #097BA9;
}
.aside-new ul .nav-link.nav-toggle.active {
  color: #097BA9;
  background-color: #E9F2FF;
}
.aside-new ul .nav-link.nav-toggle.active::before {
  top: calc(50% - 8px);
  transform: rotate(-45deg);
  border-left: 2px solid #097BA9;
  border-bottom: 2px solid #097BA9;
}
.aside-new ul .nav-link strong {
  padding-left: 12px;
  pointer-events: none;
}
.aside-new ul .navmore-link {
  position: relative;
  margin: 0 auto;
  border-radius: 5px;
  display: none;
}
.aside-new ul .navmore-link.active {
  display: block;
}
.aside-new ul .navmore-link a {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  padding: 10px 44px;
  font-weight: 600;
  font-size: 16px;
  color: #2A2A2A;
  border-radius: 5px;
}
.aside-new ul .navmore-link a:hover {
  color: #097BA9;
  background: #E9F2FF;
}

.back-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}
.back-btn:hover {
  color: #e2b211;
}

.pan-btn {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid #f1f1f1;
}
.pan-btn li {
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
}
.pan-btn button {
  width: 100%;
  background: none;
  border: none;
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  text-align: start;
  padding-left: 50px;
}
.pan-btn button:hover,
.pan-btn button :active {
  background: #eef1ff;
}

.plus-btn {
  display: flex;
  align-items: center;
  height: 50px;
}
.plus-btn button {
  background: none;
  border: none;
  padding: 0;
  margin-left: 20px;
}
.plus-btn button:hover {
  color: #e2b211;
}

.booking-box .track-icon {
  align-self: center;
  position: relative;
  width: 24px;
}

.number-grid {
  list-style: none;
}

.number-grid > div {
  position: relative;
  padding-bottom: 70px;
}
.number-grid > div .icon-wrapper {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 2;
}

.number-grid > div::before {
  content: "";
  color: #4e2a14;
  position: absolute;
  left: 0;
  width: 60px;
  height: 60px;
  background: #eef1ff;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  font-weight: 600;
  z-index: 1;
}

.number-grid > div::after {
  content: "";
  position: absolute;
  left: 30px;
  top: 10px;
  width: 1px;
  height: 100%;
  border: #e0e0e0;
  border-left-style: dotted;
  z-index: 0;
}

.number-grid > div:last-child::after {
  display: none;
}

.fs-18 {
  font-size: 18px;
}

.fw-500 {
  font-weight: 500;
}

.radio-card {
  width: 280px;
  min-width: 280px;
  border-radius: 12px;
  background: #F6F6F6;
  position: relative;
  padding: 10px;
}
.radio-card .form-check-label {
  width: 100%;
  height: 100%;
  position: absolute;
}
.radio-card .img-box {
  width: 180px;
  height: 80px;
  display: flex;
  align-items: center;
  margin: 4px 0;
}
.radio-card .img-box img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.border-btn {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  text-decoration: underline;
  font-weight: 500;
  font-size: 18px;
  color: #0D1A5A;
}
.border-btn:hover {
  color: #097BA9;
}

.normal-btn {
  background: none;
  border: 1px solid black;
}
.normal-btn:hover {
  background: rgb(14, 31, 110);
  background: linear-gradient(90deg, rgb(14, 31, 110) 0%, rgb(17, 46, 219) 100%);
  color: #FAFAFA;
  border: 1px solid #1734b8 !important;
}

.eye-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 40px;
  top: 30%;
  color: #aaaaaa;
}

.status-card {
  background: #FFFFFF;
  border-radius: 25px;
  font-size: 12px;
  padding: 10px 0;
}
.status-card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.status-card ul li {
  padding: 8px 10px;
}
.status-card ul li:not(:last-child) {
  border-bottom: 1px solid #F3F3F3;
}

.gm-style-iw {
  border-radius: 25px !important;
}
.gm-style-iw .gm-ui-hover-effect {
  right: 8px !important;
  top: 8px !important;
  scale: 2 !important;
}

.skeleton {
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(219, 219, 219, 0.5), transparent);
  animation: loading 1.5s infinite;
}

.skeleton > * {
  visibility: hidden;
}

@keyframes loading {
  to {
    left: 100%;
  }
}
.input-box {
  display: block;
  height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  width: 100%;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
}
.input-box.input-file input[type=file] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: transparent;
  opacity: 0;
  z-index: 1;
}
.input-box.input-file span {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 0;
}

.input-date {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  position: relative;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%231a31b2" viewBox="0 0 16 16"><path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/> <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/> </svg>');
  background-repeat: no-repeat;
  background-size: 1.1rem 1.1rem;
  background-position: calc(100% - 10px) center;
}

.input-date::-webkit-inner-spin-button,
.input-date::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.button-default {
  border-color: #b3b3b3;
}
.button-default:hover, .button-default:focus {
  background-color: #eee;
  border-color: #999;
}
.button-default:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.15);
}

.button {
  display: inline-block;
  padding: 0.56rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 1px solid #212529;
  border-radius: 0.375rem;
  background-color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-card textarea {
  min-height: 60px;
}

.date-picker-body {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 1000;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1490196078);
  padding: 10px;
  border-radius: 10px;
  right: 20px;
  top: 130px;
}

.sku-footprint table thead tr th {
  text-align: left !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  word-break: break-all;
  vertical-align: text-top;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.sku-footprint table tbody tr td {
  vertical-align: top;
  padding-bottom: 15px !important;
  position: relative;
}
.sku-footprint table tbody tr td i {
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  padding-top: 2px;
}
.sku-footprint table tbody tr td input {
  padding-left: 5px;
  text-align: center;
}
.sku-footprint table tbody tr td input::-webkit-outer-spin-button,
.sku-footprint table tbody tr td input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.sku-footprint table tbody tr td input[type=number] {
  -moz-appearance: textfield;
}

.vision {
  position: absolute;
  top: 23px;
  right: 10px;
  border: none;
  background: none;
}
.vision .icon {
  color: #515456;
}

.flot-btn {
  width: 60px;
  height: 60px;
  position: fixed;
  z-index: 29;
  right: 15px;
  bottom: 20px;
  background: #1935b7;
  background: linear-gradient(354deg, #1935b7 6%, #0c154a 96%);
  border-radius: 50%;
  outline: none;
  border: none;
}
.flot-btn img {
  width: 40px;
}

.chat-container {
  position: fixed;
  bottom: -400px;
  right: 15px;
  width: 300px;
  height: 380px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 9.3999996185px 0px rgba(0, 0, 0, 0.2509803922);
  display: flex;
  flex-direction: column;
  z-index: 30;
  transition: 200ms all;
}
.chat-container.active {
  bottom: 15px;
}
.chat-container .chat-header {
  width: 100%;
  height: 50px;
  border-radius: 10px 10px 0 0;
  background-color: #EBEEFF;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0 11px;
}
.chat-container .chat-header button {
  min-width: 24px;
  width: 24px;
  min-height: 24px;
  height: 24px;
  background: transparent;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.chat-container .chat-body {
  flex: 1;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.chat-container .chat-body .chat-list {
  list-style-type: none;
  padding: 15px 11px;
  margin: 0;
}
.chat-container .chat-body .chat-list .chat-sender,
.chat-container .chat-body .chat-list .chat-reciever {
  margin-bottom: 26px;
}
.chat-container .chat-body .chat-bubble {
  position: relative;
  display: inline-flex;
  gap: 11px;
  align-items: flex-end;
}
.chat-container .chat-body .chat-bubble .chat-avatar {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.chat-container .chat-body .chat-bubble .chat-content {
  word-wrap: break-word;
  max-width: 85%;
  padding: 8px 12px;
  font-size: 14px;
  position: relative;
}
.chat-container .chat-body .chat-bubble .chat-content::before {
  content: attr(data-time);
  position: absolute;
  left: 0;
  bottom: -17px;
  font-size: 11px;
  color: #2A2A2A;
  white-space: nowrap;
}
.chat-container .chat-body .chat-reciever {
  text-align: right;
}
.chat-container .chat-body .chat-reciever .chat-bubble {
  text-align: left;
  flex-direction: row-reverse;
}
.chat-container .chat-body .chat-reciever .chat-bubble .chat-content {
  background-color: #f3f6f7;
  border-radius: 11px 11px 0 11px;
}
.chat-container .chat-body .chat-reciever .chat-bubble .chat-content::before {
  left: unset;
  right: 0;
}
.chat-container .chat-body .chat-sender .chat-bubble {
  text-align: left;
}
.chat-container .chat-body .chat-sender .chat-bubble .chat-content {
  background-color: #dee1f2;
  border-radius: 11px 11px 11px 0;
}
.chat-container .chat-footer {
  width: 100%;
  height: 50px;
  background-color: #EBEEFF;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  padding: 8px 11px;
}
.chat-container .chat-footer input {
  width: 100%;
  height: 34px;
  border-radius: 30px;
  border: 1px solid transparent;
  font-size: 14px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.chat-container .chat-footer input:focus {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.18rem rgba(13, 110, 253, 0.2509803922);
  outline: 0;
}
.chat-container .chat-footer input::-moz-placeholder {
  color: #BBBBBB !important;
}
.chat-container .chat-footer input::placeholder {
  color: #BBBBBB !important;
}
.chat-container .chat-footer button {
  display: flex;
  min-width: 34px;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  justify-content: center;
  align-items: center;
  background: #0F1C61;
  color: #fff;
  border: none;
  outline: none;
  margin-left: 11px;
}
.chat-container .chat-footer button:disabled {
  background: #646fa8;
}

.chat-active {
  overflow: hidden;
}
.chat-active .chat-container.active {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
  border-radius: 0;
  transition: none;
}
.chat-active .chat-container.active .chat-header,
.chat-active .chat-container.active .chat-footer {
  border-radius: 0;
}

.like-content {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  font-size: 11px;
  font-weight: 500;
  bottom: -15px;
}
.like-content button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  font-size: 12px;
}
.like-content .like {
  color: #00a699;
}
.like-content .dislike {
  color: #da2931;
}

.chat-container #chat-lang {
  outline: none;
  font-size: small;
  background-color: transparent;
  border: none;
}

.typing {
  position: relative;
  top: -10px;
}
.typing span {
  content: "";
  animation: blink 1.5s infinite;
  animation-fill-mode: both;
  height: 10px;
  width: 10px;
  background: #0F1C61;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
}
.typing span:nth-child(2) {
  animation-delay: 0.2s;
  margin-left: 15px;
}
.typing span:nth-child(3) {
  animation-delay: 0.4s;
  margin-left: 30px;
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
.tracking-modal .modal-header {
  border-bottom: none !important;
}
.tracking-modal .input-box input {
  border: none;
  outline: none;
  border-bottom: 0.5px solid #ccc;
  padding: 10px 0;
}
.tracking-modal .input-box ::-moz-placeholder {
  color: #2a2a2a !important;
}
.tracking-modal .input-box ::placeholder {
  color: #2a2a2a !important;
}

.upload-box {
  background: #f2f6f9;
  border-radius: 8px;
}
.upload-box h3,
.upload-box h6 {
  display: flex;
  justify-content: center;
  color: #949494;
}
.upload-box h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
}
.upload-box h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding-bottom: 30px;
}

.drag-box {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px 50px;
  border: 2px dashed rgba(148, 148, 148, 0.7);
  border-radius: 6px;
}
.drag-box span {
  position: relative;
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  pointer-events: none;
  padding-top: 10px;
  color: #6fb0eb;
}
.drag-box span a {
  color: #1881e1;
}
.drag-box input {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.file-import {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #f2f6f9;
  padding: 7px 9px;
}
.file-import .file-importicon {
  width: 34px;
  min-width: 34px;
  height: 34px;
  margin: 5px;
  margin-right: 10px;
}
.file-import .file-importicon[data-src$=".pdf"] {
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" viewBox="0 0 16 16"><path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/><path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/></svg>') no-repeat;
}
.file-import .file-importicon[data-src$=".png"], .file-import .file-importicon[data-src$=".gif"], .file-import .file-importicon[data-src$=".jpg"], .file-import .file-importicon[data-src$=".jpeg"] {
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" viewBox="0 0 16 16"><path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/><path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/></svg>') no-repeat;
}
.file-import .file-importicon[data-src$=".doc"], .file-import .file-importicon[data-src$=".docx"] {
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" class="bi bi-file-earmark-word" viewBox="0 0 16 16"><path d="M5.485 6.879a.5.5 0 1 0-.97.242l1.5 6a.5.5 0 0 0 .967.01L8 9.402l1.018 3.73a.5.5 0 0 0 .967-.01l1.5-6a.5.5 0 0 0-.97-.242l-1.036 4.144-.997-3.655a.5.5 0 0 0-.964 0l-.997 3.655L5.485 6.88z"/><path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/></svg>') no-repeat;
}
.file-import span {
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}
.file-import button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  color: #6a738d;
  font-weight: bold;
}
.file-import button:hover {
  color: blue;
}
.file-import button:active {
  color: green;
}
.file-import .icon {
  cursor: pointer;
}
.file-import .icon:hover {
  color: blue;
}
.file-import .icon:active {
  color: green;
}

.upload-btn {
  color: #ffffff !important;
  background: linear-gradient(90deg, #0e2078 -0.3%, #112ed8 130.42%);
  border: 1px solid #112ed8 !important;
  border-radius: 29px;
  font-size: 14px;
  font-weight: 600;
  padding: 9px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.input-box-2 input {
  border: none;
  outline: none;
  border-bottom: 0.5px solid #ccc;
  padding: 10px 0;
}
.input-box-2 ::-moz-placeholder {
  color: #2a2a2a !important;
}
.input-box-2 ::placeholder {
  color: #2a2a2a !important;
}

.datepicker {
  z-index: 9999 !important;
}/*# sourceMappingURL=index.css.map */